@import "../../styles/Variables";
@import "../../styles/utils";

.FormInput {
    width: 100%;
    position: relative;
    
    p {
        margin-bottom: 0.5rem;
    }

    .input-label {
        padding: 0;
        margin: 0;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;

    }

    .input-container {
        position: relative;
        &.focus-style::after {
            content: "";
            display: none;
            position: absolute;
            left: -3px;
            right: -3px;
            top: -3px;
            bottom: -3px;
            border: 2px solid rgba($kone-blue, 0.5);
            border-radius: 6px;
            pointer-events: none;
          }
    }

    &.focus {
        .input-container.focus-style::after {
            display: block;
        }

        input, textarea {
            outline: none;
        }
    }

    &.invalid {
        input {
            border: 1px solid #C71F25;
        }
    }

    .error {
        padding: 0;
        margin: 0;
        bottom: -1.25rem;
        position: absolute;
        left: 0;
        font-size: 14px;
        color: #C71F25;
    }

    input {
        box-sizing: border-box;
        width: 100%;
        border: 1px solid $kone-blue;
        border-radius: 4px;
        font-family: KoneFont,'Microsoft YaHei','HeiTi SC', 'DroidSansFallback', sans-serif;
        font-size: 1rem;
        line-height: 30px;
        padding: 0 0.5rem;
    }
    .bottomline {
        width: 100%;
        height: 2px;
        background-color: $light-gray;
    }
    .focus {
        background-color: $kone-blue;
    }
    .invalid {
        background-color: #C71F25;
    }
    div {
        display: flex;
    }
    .required {
        padding-left: 0.3rem;
        color: $kone-blue;
        padding-left: 0.3rem;
    }
    textarea {
        width: calc(100% - 4px);
        height: 7.5rem;
        margin-bottom: 0px;
        font-family: inherit;
        padding: 6px 12px;
        border: 1px solid $kone-blue;
        resize: none;
        border-radius: 4px;
    }

    // Honeypot classes. Intentionally vague so bots can't figure the meaning
    &.type-1 {
        display: none;
    }

    &.type-2 {
        position: absolute;
        left: -9999px;
    }

    &.type-3 {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
}