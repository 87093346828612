@import "../../styles/Variables";
@import "../../styles/utils";

.FormRadio {
  .label-container {
    // display: flex;
  }
  .label {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }

  .required {
    padding-left: 0.3rem;
    color: $kone-blue;
    padding-left: 0.3rem;
  }

  .radio-container {
    position: relative;
    display: flex;

  }

  &.focused {
    .radio-container::after {
      opacity: 1;
    }
  }

  &.invalid {
    ul {
      // border: 1px solid #C71F25;
      border-radius: 6px;
      left: -3px;
      right: -3px;
      top: -3px;
      bottom: -3px;
    }
  }

  &.size-big {
    ul {
      line-height: 2.5rem;
      font-size: 18px;
    }

  }
  
  ul {
    border: 1px solid transparent;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    .radio-item {
      display: flex;
      position: relative;
      float: left;
      width: 100%;
      height: 24px;
      margin-top: 5px;
      margin-bottom: 5px;

      input[type=radio]{
        position: absolute;
        visibility: hidden;
      }

      label{
        display: block;
        position: relative;
        padding: 0 0 0 26px;
        height: 20px;
        cursor: pointer;
      }

      .check{
        display: block;
        position: absolute;
        border: 1px solid $kone-blue;
        border-radius: 100%;
        height: 16px;
        width: 16px;
        top: 2px;
        pointer-events: none;
      }

      .check::before {
        display: block;
        position: absolute;
        content: '';
        border-radius: 100%;
        height: 12px;
        width: 12px;
        top: 2px;
        left: 2px;
        margin: auto;
      }
      
      input[type=radio]{
        position: absolute;
        visibility: hidden;
      }
    
      input[type=radio]:checked ~ .check {
        border: 1px solid $kone-blue;
      }
      
      input[type=radio]:checked ~ .check::before{
        background: $kone-blue;
      }
      
    }
    
  }
  

}
