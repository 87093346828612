@import "../../styles/Variables";
@import "../../styles/utils.scss";

.ToastContainer {
  position: relative;

  &--page-wide {
    display: flex;
    position: fixed;
    top: 8rem;
    width: 100%;
    z-index: 100;

    @include respond($phone-big) {
      top: 11.5rem;
    }
  }
}