@import "../../../../styles/Variables";
@import "../../../../styles/utils.scss";


.DesignNameEditor {
  display: flex;
  align-items: center;
  max-width: 50rem;
  height: 3rem;
  width: 100%;

  @include respond($phone-big) {
    height: unset;       
  }

  &--editing {
    @include respond($phone-big) {
      flex-direction: column;       
    }
  }

  .design-name {
    font-size: 1.5rem;
  }

  .design-name-input-container {
    flex: 1;

    @include respond($phone-big) {
      width: 100%;     
    }
  }

  .design-name-input {
    border: 1px solid $kone-blue;
    width: 100%;
    padding: 10px 12px;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1.1rem;

    &:focus {
      outline: none;
    }
  }

  .buttons-container {
    // flex: 0 0 4rem;
    display: flex;

    @include respond($phone-big) {
      margin-top: 0.5rem;
      width: 100%;
      justify-content: flex-end;
    }

    .Button {
      margin-left: 0.5rem;
      width: 6.5rem;
    }
  }

  .icon-edit {
    width: 2.1rem;
    height: 2.1rem;     
    margin-left: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    @include respond($tablet-portrait) {
    }

    .svg-icon {
      width: 100%;
      height: 100%;
      fill: $kone-blue;
    }
  }
}