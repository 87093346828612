@import "../../styles/Variables";
@import "../../styles/utils.scss";

.ErrorPage {
  background-color: $layout-bg;


  .Header {
    border-bottom: 1px solid $border-color;
  } 

  .NavBar {
    display: none;
  }

  .error {
    margin-top: 160px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .head {
      font-size: 30px;
      text-transform: uppercase;
    }

    span {
      width: 70px;
      height: 3px;
      border-radius: 3px;
      background: black;
      margin-bottom: 2rem;
    }

    .info {
      font-size: 18px;
      margin-bottom: 3rem;
    }
    
    .btn {
      @include create-navbar-button();
      padding: 0.5rem 3.5rem;
      line-height: 20px;
      margin: 0;
    }

    .report-button {
      border: 2px solid $kone-blue;
    }
    
    .home-button {
      @include create-kone-button();
      line-height: 20px;
    }

  }
}