.DownloadPDFButton {
  cursor: pointer;

  &:hover {
    //
  }

  &.disabled {}

  &.downloaded {}

  .download-button {


    &:focus {

    }
  }

  .download-link {
    // display: none;
  }

  .hidden-button {
    display: none;
  }
}