@import "../../../../styles/Variables";
@import "../../../../styles/utils";


.ExpiredLinkError {
  margin-top: 80px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 6rem 1rem 0;

  @include respond($phone) {
    font-size: .9rem;
  }

  &__heading {
    margin-top: 0;
    font-weight: 400;
    position: relative;
    padding-bottom: 1.6rem;
    margin-bottom: 1.6rem;

    @include respond($phone) {
      font-size: 1.5rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      height: 4px;
      width: 30px;
      background-color: #C7CACC;
    }
  }

  &__info {
    margin: 0;
    padding: 0;
  }

  &__sales-contact-heading {
    margin-top: 0;
    margin-bottom: 0.5rem;
    color: #717A80;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 400;
  }

  &__contact-box {
    margin-top: 1.5rem;
    background-color: #EEF0F1;
    padding: 2.1rem 1.9rem;
    box-sizing: border-box;
    max-width: 100%;
    width: 26rem;

    p {
      margin: 0;
      padding: 0;
    }
  }

  .contact-info {
    color: $kone-blue;
    margin-top: 1rem;

    .contact-info-block {
      display: flex;
      align-items: center;
      white-space: nowrap;

      &:not(:first-of-type) {
        margin-top: .5rem;
      }
    }

    .svg-icon {
      margin-right: 0.45rem;
      fill: $kone-blue;
      height: 1.1rem;
      width: 1.1rem;

      @include respond($tablet-portrait-extra) {
        height: 0.8rem;
        width: 0.8rem;
      }
    }
  }
}