@import "../../../../styles/Variables";
@import "../../../../styles/utils";

 
// The styling for this view ended up as a mess since initially the idea was 
// to keep the heights of the designs and their theme headers the same, 
// along with the blank design section. This requirement was later 
// scrapped but the behavior is still there, causing especially 
// responsive styling to be all over the place.

// Luckily the startup flow UI traditionally gets remade every two months
// or so, so maybe this can be created anew soon enough (*knocks on wood*).
// 2021/03/02 Joonas K

$info-container-horizontal-padding: 4.4rem;
$info-container-horizontal-padding-width-2: 2rem;
$info-container-horizontal-padding-tablet-mode: 2rem;
$design-container-width: 1160px;
$design-container-width-laptop: 1040px;
$design-grid-gap: 10px;
$sub-header-vertical-margin: 2rem;

$tablet-mode-breakpoint: 1000px;
$predesign-theme-laptop-breakpoint: 1125px;
$phone-mode-breakpoint: $phone-big;

.ProductFamilyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  position: relative;

  @include respond($tablet-mode-breakpoint) {
    margin-bottom: 1.5rem;
  }

  &__header {
    display: flex;
    @include respond($tablet-mode-breakpoint) {
      margin-top: 2.7rem;
    }

    @include respond($phone-mode-breakpoint) {
      flex-direction: column;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 4rem;
    margin-top: 2rem;

    @include respond(1200px) {
      margin-top: 0;
    }

    @include respond($tablet-mode-breakpoint) {
      margin-left: 1.25rem;
      margin-top: 0;
      align-items: center;
      justify-content: center;
    }

    @include respond($phone-big) {
      margin: 1rem;
    }
  }

  &__image-container {
    img {
      width: 100%;
      height: auto;
    }

    flex-basis: 37.5%;

    @include respond($tablet-mode-breakpoint) {
      flex-basis: 50%;
    }

    @include respond($phone-big) {
      flex-basis: 100%;
    }
  }

  &__products {
    margin-top: 1rem;
  }

  &__title {
    font-size: 2.25rem;
    margin-bottom: 1.25rem;
    padding-top: 0.6rem;
    font-weight: 400;
    text-transform: uppercase;

    @include respond(1200px) {
      margin-top: 0.75rem;
    }

    @include respond($tablet-mode-breakpoint) {
      position: absolute;
      font-size: 1.75rem;
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
      top: -0.8rem;
      margin: 0;
      padding: 0;
    }

    @include respond($phone-mode-breakpoint) {
      font-size: 1.75rem;
      width: 100%;
      text-align: center;
      top: 0.5rem;
    }
  }

  &__desc {
    margin: 0;
    font-size: 1.15rem;
    line-height: 1.625rem;

    @include respond($tablet-mode-breakpoint) {
      font-size: 1rem;
      line-height: 1.375rem;
    }
    
    @include respond($phone-mode-breakpoint) {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }
}

.DesignsContainer {
  background-color: #f5f6f7;
  padding: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  &__content {
    width: 100%;
    padding: 0 3rem;

    @include respond($desktop-small) {
      padding: 0 2rem;
    }

    @include respond($tablet-mode-breakpoint) {
      padding: 0 1rem;
    }
  }

  &__sub-header {
    margin: $sub-header-vertical-margin 0;
    text-align: center;
  }

  .PredesignTheme + .DesignsContainer__sub-header {
    // margin-top: calc(#{$sub-header-vertical-margin} - #{$design-grid-gap}); // Adjust for the gap
  }

  // Space between themes
  .PredesignTheme + .PredesignTheme {
    margin-top: 1.5rem;
  }
}

.BlankDesign.PredesignTheme {
  // background: url(thumbnails/predesign-themes/blank-1134x260.png);
  background-size: cover !important;
  position: relative;
  margin-right: 0; 

  @include respond-between($phone-mode-breakpoint + 1px, $tablet-mode-breakpoint) {
    background-position-x: 25% !important;
    background-size: 165% !important;
  }

  @include respond($phone-mode-breakpoint) {
    background-position-x: 25% !important;
  }

  .PredesignTheme__heading {
 
    @include respond($tablet-mode-breakpoint) {
      &.PredesignTheme__heading {
        flex-basis: calc(75% - #{$design-grid-gap});
      }
    }
  }

  .PredesignTheme__header {
    text-transform: unset;
    font-size: 1.5rem;

    @include respond($predesign-theme-laptop-breakpoint) {
      // margin-top: 2.75rem;
    }
  }

  .PredesignTheme__info-container {
    padding-right: 0;
  }

  .PredesignTheme__action-button {
    @include btn-lg();
    @include btn-white();
    margin-top: 2.5rem;
    height: 2.5rem;
    width: 12.5rem;

    @include respond($predesign-theme-laptop-breakpoint) {
      margin-top: 1.5rem;
    }

    @include respond($tablet-mode-breakpoint) {
      margin-top: 2.5rem;
    }
  }
  
  .Predesign {
    background-color: unset;
    &__sprite-container {
      top: 50%;

      @include respond($tablet-mode-breakpoint) {
        width: 60%;
      }

      @include respond($tablet-portrait) {
        width: 70%;
      }

      @include respond(620px) {
        width: 80%;
      }
    }

    @include respond($tablet-mode-breakpoint) {
      flex-basis: calc(100% / 4 - 10px);
    }
  }

  &__header {
    font-weight: 400;
    font-size: 1.35rem;
  }

  &__description {
  }

  @include respond($phone-mode-breakpoint) {
    .Predesign {
      position: absolute;
      width: 35%;
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
      // height: 5rem;

      @include respond(420px) {
        top: 60%;
        width: 40%;
      }
    }

    .PredesignTheme__heading.PredesignTheme__heading {
      flex-basis: 100%;
      height: 24rem;
      margin: 0;
    }

    .PredesignTheme__header {
      margin-top: 1.25rem
    }

    .PredesignTheme__description {
      margin-bottom: 16rem; // Space for the car image
    }

    .PredesignTheme__info-container {
      margin: 0;
      padding-right: $info-container-horizontal-padding-tablet-mode;
    }

    .PredesignTheme__action-button {
      position: absolute;
      bottom: 1.2rem;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 4rem);

      
    }
  }
}

$padding-left: 6.75rem;
$padding-left-mobile: 1.5rem;

.ProductContainer {
  display: flex;
  align-items: center;
  flex-direction: column;

  .product-selection-box {
    border: 2px solid $gray;
    width: 100%;
    cursor: pointer;
    display: flex;
    padding: 1.1rem 0;
    flex-wrap: wrap;
    position: relative;
    z-index: 0;
    margin-top: -2px; // Offset borders

    @include respond($phone-big) {
      font-size: 0.85rem;
    }

    &__icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 $padding-left;

      @include respond($tablet-mode-breakpoint) {
        padding-top: 0.7rem;
      }

      @include respond($phone-big) {
        position: absolute;
        padding-top: 0;
        right: 1.5rem;
        top: 1.5rem;
      }
    }

    &__icon {
      width: 2rem;
      height: 2rem;
      fill: #aaafb3;
    }

    &__product-data {
      flex: 1 0;
    }

    &__title {
      margin: 0;
      margin-bottom: 0.5rem;
      font-weight: 400;
      font-size: 1.25rem;
      padding-top: 0.6rem;
      color: $kone-blue;

      @include respond($phone-big) {
        padding-left: $padding-left-mobile;
        padding-right: 4rem;
      }
    }

    &__desc {
      font-family: Arial, sans-serif, 'Microsoft YaHei','HeiTi SC';
      font-size: 0.9rem;
      line-height: 1.2rem;
      min-height: 4.25rem; // Can fit three rows before growing in size
      margin: 0;
      margin-bottom: 0.5rem;
      // line-height: 1.4rem;

      @include respond($tablet-mode-breakpoint) {
        min-height: 3rem; // Two rows
      }

      @include respond($phone-big) {
        padding-left: $padding-left-mobile;
        padding-right: 4rem;
      }
    }

    &__select-container {
      flex: 1 0 100%;
      margin-left: $padding-left;
      margin-top: 0.5rem;

      @include respond($tablet-mode-breakpoint) {
        margin-top: 1rem;
      }

      @include respond($phone-big) {
        margin-left: $padding-left-mobile;

        flex: 1 1 100%;
        margin-right: 1rem;
      }
    }

    &__select {
      display: flex;

      .label-container {
        display: flex;

        .label {
          margin: 0;
        }
      }

      .select-container {
        margin-left: 1rem;
        width: 8rem;

        @include respond($phone-big) {
          width: unset;
          flex: 1;
        }
      }
    }

    &__product-info {
      display: flex;
      align-items: center;
      margin-left: 2rem;
      flex: 0 0 52%;

      @include respond($tablet-mode-breakpoint) {
        // Last value of the calc could be tought of as right padding
        flex: 0 0 calc(100% - #{$padding-left} - 2rem);
        margin: 0;
        padding-left: $padding-left;
      }

      @include respond($phone-big) {
        flex: 100%;
        padding-left: $padding-left-mobile;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &.is-new-product {
    .product-selection-box {

      @include respond($tablet-mode-breakpoint) {
        .product-selection-box__title {
          margin-bottom: 1rem;
        }

        .product-selection-box__icon-container {
          padding-top: 1.1rem;
        }
      }
      // Create more space at the top of the container for the "new" badge
      @include respond($phone-big) {
        padding-top: 1.5rem;

        // Need to move the icon down so it aligns with the title
        .product-selection-box__icon-container {
          top: 1.85rem;
          padding-top: 0;
        }
      }
    }
  }

  &--selected {
    .product-selection-box {
      border: 2px solid $kone-blue;
      z-index: 1;

      &__icon {
        fill: $kone-blue;
      }
    }
  }
}

.ProductInfoBlock {
  display: flex;
  align-items: center;
  flex: 1;

  @include respond($phone-big) {
    &:not(:last-of-type) {
      margin-bottom: 0.5rem;
    }
  }

  &__icon {
    width: 2rem;
    height: 2rem;
  }

  &__values {
    line-height: 1.4rem;
    margin-left: 0.75rem;

    @include respond($phone-big) {
      line-height: 1.2rem;
      margin-left: 1rem;
    }

    p {
      margin: 0;
    }
  }

  &__label {
    color: #566066;
    text-transform: capitalize;

    @include respond($phone-big) {
      font-size: 0.9rem;
    }
  }

  &__value {
  }
}

.Predesign {
  position: relative;
  flex-basis: calc(25% - #{$design-grid-gap});
  background-color: #e3e5e6;
  display: block;
  color: #3d464c;

  @include respond($tablet-mode-breakpoint) {
    flex-basis: calc(100% / 3 - #{$design-grid-gap});
  }

  @include respond($phone-mode-breakpoint) {
    flex-basis: calc(50% - #{$design-grid-gap});
  }

  &__sprite-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;

    &.shape-HOMELIFT_9_12_24,
    &.shape-NANOSPACE_11_10_21 {
      width: 36%;
    }

    &.shape-SQUARE {
      width: 40%;
    }

    &.shape-INDIA_11_10_22 {
      width: 39%;
    }
    
    &.shape-WIDE {
      @include respond($phone-mode-breakpoint) {
        width: 43%;
      }
    }

    &.shape-WIDE_AU_14_16_24 {
      @include respond($phone-mode-breakpoint) {
        width: 43%;
      }
    }

    &.shape-DEEP_AU_14_20_24 {
      @include respond($phone-mode-breakpoint) {
        width: 43%;
      }
    }

    &.shape-DEEP {
      @include respond($phone-mode-breakpoint) {
        width: 43%;
      }
    }

  }

  &__name {
    position: absolute;
    margin: 0;
    left: 0;
    bottom: 6%;
    right: 0;
    text-align: center;
    
    @include respond ($tablet-mode-breakpoint) {
    }
  }

  // Force height equal to width
  // https://stackoverflow.com/questions/5445491/height-equal-to-dynamic-width-css-fluid-layout#comment21604943_6615994
  &::before {
    content: "";
    display: block;
    margin-top: 100%;
  }
}

.rtl {
  .ProductFamilyContainer {
    &__header {
      display: flex;
    }

    &__info {
      margin-right: 4rem;
      margin-left: 0;
    }
  }

  .ProductContainer {
    &__select-container {
      margin-left: 0;
      margin-right: $padding-left;
    }

    &__select {
      .select-container {
        margin-left: 0;
        margin-right: 1rem;
      }
    }

    &__product-info {
      margin-left: 0;
      margin-right: 2rem;
    }
  }

  .ProductInfoBlock {
    &__values {
      margin-left: 0;
      margin-right: 0.75rem;
    }
  }
}

.PredesignTheme {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-right: -#{$design-grid-gap}; // offset the right margin of the inner elements (so the grid goes all the way to the end)
  color: white;
  
  & > * {
    // the spacing between elements of the "grid"
    margin-right: $design-grid-gap;
    margin-bottom: $design-grid-gap;
  }
  
  &__heading {
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.67);
    box-sizing: border-box;
    position: relative;
    background-size: cover !important;
    
    // Smaller than this and the background is too small
    @include respond-between(670px, $tablet-mode-breakpoint) {
      background-size: 165% !important;
    }

    @include respond($phone-mode-breakpoint) {
      // background-size: 165% !important;
    }

    &.width-4 {
      flex-basis: 100%;

      &::before {
        content: "";
        display: block;
        // aspect ratio of 4 : 1, so it matches the design block dimensions
        margin-top: calc(25% - #{$design-grid-gap}); 
      }
    }

    &.width-3 {
      flex-basis: calc(75% - #{$design-grid-gap});
    }

    &.width-2 {
      flex-basis: calc(50% - #{$design-grid-gap});

      .PredesignTheme__header {
        left: $info-container-horizontal-padding-width-2
      }

      .PredesignTheme__description {
        left: $info-container-horizontal-padding-width-2
      }
    }

    &.width-1 {
      flex-basis: calc(25% - #{$design-grid-gap});
    }

    @include respond($desktop-small) {
      // height: $design-container-width-laptop / 4 - $design-grid-gap;
    }

    @include respond($tablet-mode-breakpoint) {
      &.PredesignTheme__heading {
        // force full width regardless of width class
        height: 225px;
        flex-basis: 100%;
      }
    }
  }

  &__info-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 $info-container-horizontal-padding;
    
    @include respond($tablet-mode-breakpoint) {
      padding: 0 $info-container-horizontal-padding-tablet-mode;
    }

    @include respond($phone-mode-breakpoint) {
      padding: 0 1.1rem;
    }
  }


  &__header {
    // position: absolute;
    text-transform: uppercase;
    font-size: 2.25rem;
    margin: 0 0 1.25rem;
    font-weight: 400;

    @include respond($predesign-theme-laptop-breakpoint) {
      
    }

    @include respond($tablet-mode-breakpoint) {
      font-size: 1.5rem;
      
    }

    @include respond($phone-mode-breakpoint) {
      
    }
  }

  &.local-designs {
    .PredesignTheme__header {
      text-transform: none;
      font-size: 1.5rem;
    }
  }

  &__description, &__subtitle {
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.625rem;
    
    @include respond($tablet-mode-breakpoint) {
      font-size: 1rem;
      line-height: 1.375rem;
    }

    @include respond($phone-mode-breakpoint) {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }
  }

  &__subtitle {
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  &__predesign {
  }

  &.local-designs {
    .PredesignTheme__heading {
      text-shadow: none;
    }
  }

  &.theme-CREATIVELIFE, &.theme-CLASSICCHIC { // , &.theme-SUNGLOW, &.theme-COOLBREEZE 
    color: $base-text-color;
    
    .PredesignTheme__heading {
      text-shadow: none;
    }
  }

  &.theme-MONOSPACE-300 {
    .PredesignTheme__heading {
      display: none;
    }
  }

  &.theme-MONOSPACE-100 {
    .PredesignTheme__heading {
      display: none;
    }
  }

  &.india-neb {
    .PredesignTheme__header {
      .neb-heading {
        display: inline-flex;
        padding: 0.5rem 1.2rem;
        background-color: #2f2f33;
        text-transform: capitalize;
        text-shadow: none;

        &.SUNGLOW {
          .first-half { color: #918471; }
          .second-half { color: #faac49; }
        }

        &.DEWDROP {
          .first-half { color: #376b8b; }
          .second-half { color: #918471; }
        }

        &.SANDSTONE {
          .first-half { color: #c6856a; }
          .second-half { color: #918471; }
        }

        &.COOLBREEZE {
          .first-half { color: #73665e; }
          .second-half { color: #c6856a; }
        }

        &.MILKYWAY {
          .first-half { color: #918471; }
          .second-half { color: #376b8b; }
        }

        &.PUREELEGANZ {
          .first-half { color: #629bc1; }
          .second-half { color: #eb9f86; }
        }
      }
    }
  }
}
