@import "../../styles/Variables";
@import "../../styles/utils.scss";

$tablet-mode-breakpoint: $tablet-portrait + 100px;
$phone-mode-breakpoint: $phone-big + 100px;

.GendocShareLoadingView {
  background-color: rgb(253, 253, 253);
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 0;
    
    
    @include respond($tablet-mode-breakpoint) {
      flex: 1 0;
    }

    @include respond($phone-mode-breakpoint) {
      text-align: center;
      flex: 0;
      padding: 2.5rem 1.5rem;
    }
  }

  .header {
    font-weight: 400;
    // font-size: 1.8rem;
    position: relative;
    margin: 0 0 2.25rem;

    @include respond($phone-mode-breakpoint) {
      font-size: 1.5rem;
    }

    &::after {
      content: "";
      position: absolute;
      height: 7px;
      width: 60px;
      background-color: #D4D7D9;
      bottom: -1.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // flex: 1 1;
    
    @include respond($tablet-mode-breakpoint) {
      margin-bottom: 2rem;
      flex: 0 1;
    }

    @include respond($phone-mode-breakpoint) {
      padding: 0 1rem;
    }
  }

  .Loader {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin-top: 2rem;
    padding-bottom: 1rem;
    flex: 1 0;

    @include respond($phone-mode-breakpoint) {
      width: 100%;
    }

    .loader-container {
      position: relative;
      transform: none;
      top: unset;
      // width: 100%;

      .loadBar {
        @include respond($phone-mode-breakpoint) {
          width: calc(100% - 3rem);
        }
      }
    }

    .loader-bottom-container {
      margin-top: 1rem;
      position: relative;
      height: 57px;

      img {
        height: 100%;
      }
    }
  }
}

.InfoItem {
  display: flex;
  margin-bottom: 1rem;
  width: 37.5rem;
  align-items: center;
  
  @include respond($phone-mode-breakpoint) {
    width: 100%;
  }

  .image-container {
    margin-right: 1.5rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex: 0 0 7rem;
    height: 7rem;

    @include respond($phone-mode-breakpoint) {
      margin-right: 0.5rem;
      height: 5.5rem;
      flex: 0 0 5.5rem;
    }

    .Sprite {
      width: 100%;
      height: 100%;
      padding: 0 !important;
    }
  }

  .content-container {
    flex: 1 0;

    h4 {
      color: $kone-blue;
      font-weight: 400;
      font-size: 1.4rem;
      margin: 0;
      margin-bottom: 0.5rem;
    }

    p {
      margin: 0;
    }
  }
}

