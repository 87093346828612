@import "../../styles/Variables";


.UnsupportedBrowser {
  background-color: #656565;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
  .spacer {
    height:5em;
    background-color: #656565;
  }
  .fullWindowContainer {
    margin: 0 auto auto auto ;
    background-color: #ffffff; 
    width: 70%;
    height: auto;

    @media only screen and (max-width: $phone-big) {
      width: 95%;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-content: space-between;
      margin: auto;
      padding: 4em 4em 4em 4em;
      text-align: center;
    }
      
    h2 { 
      font-size: 2rem;
      padding-bottom: 1em;
      font-weight: 400
    }
    
    .icon-container {
      margin-top: 4em;
      margin-bottom: 5em;
    }
    
    img {
      padding-right: 1.5em;
    }
    
    img:last-child {
      padding-right: 0em;
    }
    
    button {
      padding: 0.5em 8em 0.5em 8em;
      font-family: KoneFont,'Microsoft YaHei','HeiTi SC', 'DroidSansFallback', sans-serif;
      font-size: 1.1rem;
      border-radius: 5px;
      background-color: $light-blue;
      border: none;
      color: #ffffff;
      margin-bottom: auto;
      cursor: pointer;
    }
    
    @media only screen and (max-width: 900px) {
      button {
        padding: 0.5em 5em 0.5em 5em;
      }
    
      img {
        padding-right: 0em;
        -webkit-transform: scale(0.8); /* Saf3.1+, Chrome */
        -moz-transform: scale(0.8); /* FF3.5+ */
        -ms-transform: scale(0.8); /* IE9 */
          -o-transform: scale(0.8); /* Opera 10.5+ */
            transform: scale(0.8);
                /* IE6–IE9 */
                filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713,SizingMethod='auto expand');
      }
    }
      
    @media only screen and (max-width: $tablet-portrait) and (min-width: $phone) {
      button {
        padding: 0.5em 3em 0.5em 3em;
      }
    }
  }
}


