@import '../../styles/Variables.scss';

.ActionCard {
  cursor: pointer;

  .image-container {
    position: relative;

    @media only screen and (max-width: $phone-big) {
      height: 150px;
      overflow: hidden;

      .Sprite {
        position: absolute;
      }
    }
  }

  &-container { 
    display: flex;
    align-items: center;
    position: relative;
    background-color: $darker-gray;
    color: white;
    min-height: 110px;
    padding: 25px;
    box-sizing: border-box;

    @media only screen and (max-width: $phone-big) {
      min-height: 100px;
    }
  }

  &.selected &-container {
    background-color: $dark-blue;
  }

  &-image {
    display: block;
    width: 100%;
    height: auto;
  }

  &-label {
    font-size: 18px;
    margin-right: 40px;
  }

  &-icon-container {
    position: absolute;
    bottom: 0;
    right: 0;

    box-sizing: border-box;
    padding: 9px;
    width: 40px;
    height: 40px;
    border: 1px solid white;
  }
}

.rtl {
  .ActionCard {

    &-label {
      margin-right: unset;
      margin-left: 40px;
    }

    &-icon-container {
      position: absolute;
      bottom: 0;
      left: 0;
      right: unset;
      svg {
        transform: rotate(180deg);
      }
    }
  }
}