@import "../../styles/Variables";
@import "../../styles/utils";


.ContactDialog {
    
    .selectionContainer {
        display: flex;
        margin: 40px 0 30px 0;
        p {
            margin: 0 0.5rem;
        }
    }
    .privacy-statement {
        margin: 30px 0;
    }

    .selection {
        width: 1rem;
        height: 1rem;
        border-radius: 3px;
        border: 1px solid $light-gray;
        background-color: $lighter-gray;
        &:not(.checked) {
            svg {
                visibility: hidden;
            }
        }
    }
    .checked {
        background-color: $light-blue;
        border: 1px solid $light-blue;
        position: relative;
        svg {
            margin: auto;
            position: absolute;
            top: 0; right: 0;
        }
    }
    .form {
        .location {
            display: flex;
        }
        .invalidSelection {
            border: 1px solid #C71F25;
        }

        .form-item {
            &:not(:last-of-type) {
                margin-bottom: 1.5rem;;
            }
        }

        .form-item.half-width {
            width: 50%;

            @media screen and (max-width: $phone-big) {
                width: 100%;         
            }
        }
    }

    .form-section-header {
        font-size: 1.5rem;
        font-weight: 400;
    }
    
    .footer-buttons {
        text-align: right;
        .Button {
          min-width: 128px;
          margin-right: 10px;
          &:last-child {
            margin-right: unset;
          }
        }
    
        @media only screen and (max-width: $phone-big){
          display: flex;
    
          .Button {
            flex: 1;
          }
        }
    
        @media only screen and (max-width: $phone-small){
          padding: 10px 15px;
        }
      }

    .InfoBox {
        margin: 10px 0 30px 0;
        background-color: white;
        border: 2px solid $kone-blue;
        border-radius: 4px;

        .icon-container {
            padding: 10px;
            svg {
                width: 40px;
                height: 40px;
            }
        }

        .text-container {
            .text-head {
                margin: 0;
            }
        }
    }

    .recaptcha {
        margin: 40px 0 30px 0;

        div {
        margin: auto;
        }
    }

}