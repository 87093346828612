@import "../../styles/Variables";

.Card {
  background-color: $card-bg;
  
  .card-header {
    padding:  0 40px;

    @media only screen and (max-width: $tablet-landscape) {
      padding: 0 20px;
    }

    h2 {
      font-weight: normal;
      font-size: 1.5rem;
      text-align: center;
      border-bottom: solid 1px $border-color;
      line-height: 5rem;
      margin: 0;
    }

  }
  .card-body {
    padding: 0 40px 40px 40px;
    @media only screen and (max-width: $tablet-landscape) {
      padding: 0 20px 20px 20px;
    }    
  }
}