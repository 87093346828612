@import "../../styles/Variables";
@import "../../styles/utils";
 .SecondDialogView {
     /* 
    @include custom-scrollbar(secondView);
    height: auto;
    
    // for dialogs with more content so they might need a scrollbar with smaller screens.
    &.scaling-height {
        height: 80vh;
        max-height: 36rem;
    }

    .head {
        display: flex;
        height: 4rem;
        border-bottom: 1px solid $border-color;
        margin-bottom: 1rem;
        .x {
            width: 18px;
            height: 18px;
            margin-top: 1.5rem;
            margin-left: auto;
            color: $kone-blue;
            cursor: pointer;
            svg {
                width: 18px;
                height: 18px;
            }
        }
    }

  .secondView {

    overflow: auto;
    height: calc(100% - 10rem);

      .thankYouText {
          text-align: center;
          .thankYou {
              font-size: 1.5rem;
          }
          p {
              margin: 1.5rem 3rem;
          }
      }

      .success {
        text-align: center;
        // margin-bottom: 2rem;
        svg {
            padding-bottom: 2rem;
        }
        .sent {
            font-size: 1.5rem;
            // padding-bottom: 1.5rem;
        }
        p {
            margin: 1.5rem 1.5rem 2rem 1.5rem;
        }
    }

    a {
        margin:0;
        padding:0;
    }
    
  }*/

  .thankYouText {
    text-align: center;
    font-size: 1.5rem;
    margin: 20px 0;
  }

  .newCarDesign {
    position: relative;
    height: 18rem;
    width: 15rem; 
    margin: 30px auto;
    cursor: pointer;
    text-align: start;
    background-image: url(../../assets/icons/icon-create-new.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 100px) calc(100% - 170px);
    background-color: $lighter-gray;

    div {
        position: absolute;
        bottom: 0;
        background-color: rgb(42, 46, 48);
        opacity: 0.8;
        width: 15rem;
        height: 7rem;
        margin: 0 0;
        color: white;
    }

    p {
        margin: 0;
        padding: 0.5rem 1rem 0 0.5rem;
        line-height: 16.5pt;
        font-size: 13.5pt;
    }
    .arrow {

        position: absolute;
        height: 2.7rem;
        width: 2.7rem;
        bottom: 0;
        right: 0;
        border: 1.2px solid white;

        svg {
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
  }

  .success {
    text-align: center;
    svg {
      padding-bottom: 2rem;
    }
    .sent {
      font-size: 1.5rem;
      margin: 1.5rem 0;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: center;
    .close {
      @include create-navbar-button();
      padding: 0.5rem 25%;
      border: 2px solid $kone-blue;
    }
  } 
}

.rtl {
  .SecondDialogView {
    .newCarDesign {
      .arrow {
        right: unset;
        left: 0;
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}