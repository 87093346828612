@import "../../styles/Variables";
@import "../../styles/utils";

.ContactKone {

    .card-body {
        padding-top: 2rem;
    }

    width: 100%;
    height: auto;
    background-color: white;
    margin: 0 auto;
    text-align: center;

    .form-item {
        &:not(:last-of-type) {
            margin-bottom: 1.5rem;;
        }
    }

/*     .Notification{
        max-width: 1440px;
        top: 7.5rem;
        position: fixed;

        p {
            position: relative;

            svg {
                left: -2.5rem;
            }
        }
    } */

    .bottomText {
        color: $light-gray;
        margin: 20px 0;
    }

    .privacy-statement {
        margin: 20px 0;        
    }
    .head {
        display: flex;
        position: relative;
        margin-top: 0;
        p {
            font-size: 18px;
            margin: 1.25rem;
        }
        .x {
            position: absolute;
            right: 1.25rem;
            top: 1rem;
            font-size: 1.5rem;
            margin: 0;
            color: $kone-blue;
            cursor: pointer;
        }
    }

    .header {
        font-size: 1.5rem;
    }

    .form {
        text-align: center;
        max-width: 410px;
        margin: auto;
        //margin: 2rem 1.25rem 0;

        @media only screen and (max-width: $tablet-landscape) {
            width: 70%;
        }

        @media only screen and (max-width: $phone-big) {
            width: 100%;
        }

        .selection {
            display: flex;

            p {
                margin: 0;
                margin-bottom: 0.5rem; 
            }
        }
        .invalidSelection {
            border: 1px solid #C71F25;
        }
    }

    .recaptcha {
        margin: 40px 0 30px 0;

        div {
        margin: auto;
        }
    }

    .buttons {
        margin-top: 3rem;
        button {
            margin: 0 30px;
        }
        .send {
            @include create-kone-button();
            margin: 0;
            display: block;
            width: 100%;
        }
        .disabled {
            background-color: $light-gray;
            pointer-events: none;
        }
    }

//===========
// first view specifics
    .firstView {
        height: auto
    }


//==============
// second view specifics 
    .secondView {
        height: auto;
        .thankYouText {
            width: 70%;
            margin: auto;
            text-align: center;
            p {
                margin: 1.5rem 3rem;
            }

            @media only screen and (max-width: $phone-big) {
                width: 90%;
            }
        }
        .optionsContainer {
            display: flex;
            justify-content: center;
            // border-bottom: 1px solid $border-color;
        }
        .Selection {
            position: relative;
            height: 18rem;
            width: 15rem;
            margin: 3rem 1rem;
            cursor: pointer;
            text-align: start;
            background-image: url(../../assets/icons/icon-create-new.png);
            background-repeat: no-repeat;
            background-position: calc(100% - 100px) calc(100% - 170px);
            background-color: $lighter-gray;
            div {
                position: absolute;
                bottom: 0;
                background-color: rgb(42, 46, 48);
                opacity: 0.8;
                width: 15rem;
                height: 7rem;
                margin: 0 0;
                color: white;
            }
            p {
                margin: 0.5rem;
                padding-right: 1rem;
                line-height: 16.5pt;
                font-size: 13.5pt;
            }
            .arrow {
                position: absolute;
                height: 2.7rem;
                width: 2.7rem;
                bottom: 0;
                right: 0;
                border: 1.2px solid white;
        
                svg {
                position: absolute;
                margin: auto;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                }
            }
        }
    }
}

.rtl {
    .ContactKone {
      .secondView {
          .Selection {
            .arrow {
                right: unset;
                left: 0;
                svg {
                  transform: rotate(180deg);
                }
            }              
          }
      }
    }
  }