@import "../../../../styles/Variables";
@import "../../../../styles/utils.scss";

.LocationAndRoleDialog {
  top: 1rem;
  max-height: calc(100% - 1.5rem);

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-bottom: 1rem;

    height: 17rem;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -15px;
  }

  .content-container {
    padding-bottom: 1.25rem;
  }

  .section-content {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include respond($phone-big) {
      width: 85%;
      line-height: 1.5rem;
    }
  }

  .country-section {
    position: relative;
    color: white;
    height: 22rem;
    
    .section-content, .section-image-container {
      z-index: 2;
    }

    .section-title {
      margin-top: 1.5rem;
      margin-bottom: 0.75rem;
      text-shadow: 0px 3px 6px #00000077;
    }

    .section-description {
      text-shadow: 0px 3px 6px #00000077;
    }
    

    .location-select {
      margin-top: 2rem;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      background: #005A94 0% 0% no-repeat padding-box;
      opacity: 0.45;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .role-section {
    padding-top: 2rem;
  }

  .section-image-container {
    width: 7rem;
    min-width: 7rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .Sprite, .map-icon {
      width: auto;
      height: 3.1rem;
      fill: white;
    }

    .role-image {
      padding-top: 0 !important;
      height: 5rem;
      width: 5rem;
    }
  }

  .section-title {
    margin-top: 0.75rem;
    margin-bottom: 0;
    font-size: 1.4rem;
    font-weight: 400;
  }

  .FormSelect {
    width: 17rem;
    margin-top: 1rem;

    @include respond($phone-big) {
      width: 100%;
    }
  }
}

.rtl {
  .LocationAndRoleDialog {
    
  }
}