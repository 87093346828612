@import "../../styles/Variables";

.Info {
  height: 18px;
  width: 18px;

  .svg-icon {
    height: 100%;
    width: 100%;
  }
  
}


.tooltip {
  max-width: 150px !important;
  filter: drop-shadow(0 3px 8px $dropshadow);
  opacity: 1 !important;
  padding: 8px 16px !important;
}

.tooltipwide {
  max-width: 250px !important;
  opacity: 1 !important;
  filter: drop-shadow(0 3px 8px $dropshadow);
  padding: 8px 16px !important;
}
