@import "../../styles/Variables";
@import "../../styles/utils";

$transition-duration: 0.15s;

.GiveFeedbackButton {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  height: 3.8rem;
  min-width: 3.8rem;
  max-width: 3.8rem;
  background-color: #99C6E3;
  border-radius: 50px;
  transition: all $transition-duration linear;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;

  .button-text { 
    opacity: 0;
    white-space: nowrap;
    user-select: none;
    transition: opacity 0.09s linear;
  }

  &:hover {
    max-width: 16rem;
    padding-left: 3rem;
    padding-right: 1rem;
    .button-text {
      opacity: 1;
    }

    .button-icon {
      height: 1.65rem;
      width: 1.65rem;
      left: .67rem;
      transition: all $transition-duration linear;
    }
  }
  
  .button-icon {
    left: .95rem;
    height: 2rem;
    width: 2rem;
    position: absolute;
    .svg-icon {
      width: 100%;
      height: 100%;
      fill: white;
    }
  }

}
.CompactFeedbackFormContainer {
  width: 21rem;
  background-color: white;
  position: fixed;
  // margin-left: 10rem;
  border-radius: 4px;
  box-shadow: 0px 0px 6px #00000029;
  z-index: 20000;
  bottom: 0;
  right: unset;
  
  transform: translateX(16rem);

  @include respond(2150px) {
    right: 1rem;
    transform: unset;
  }

  @include respond($phone) {
    width: unset;
    left: 1rem;
  }

  &.stick-right {
    transform: unset;
    right: 1rem;
  }
  
}