@import "../../styles/Variables";
@import "../../styles/utils";

.FormSelect {
  .label-container {
    // display: flex;
  }
  .label {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }

  .required {
    padding-left: 0.3rem;
    color: $kone-blue;
    padding-left: 0.3rem;
  }

  .select-container {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      left: -3px;
      right: -3px;
      top: -3px;
      bottom: -3px;
      border: 2px solid rgba($kone-blue, 0.5);
      border-radius: 6px;
      pointer-events: none;
      opacity: 0;
    }
  }

  &.focused:not(.disabled) {
    .select-container::after {
      opacity: 1;
    }
  }

  .select-option-group {
    font-style: normal;
    
    .select-option {
      
    }
  }

  select {
    background: white;
    width: 100%;
    padding: 0 0.75rem;
    border-radius: $button-border-radius;
    border: solid 1px $kone-blue;
    appearance: none;
    line-height: 30px;
    font-size: 16px;
    font-family: 'KoneFont','Microsoft YaHei','HeiTi SC', 'DroidSansFallback', sans-serif;
    outline: none;
    cursor: pointer;

    .select-option-header {
      color: #717A80;
    }

    .select-option-header-padding-top {
      font-size: 8px;
    }

    .select-option-header-padding-bottom {
      font-size: 3px;
    }

    .select-option {

    }

    &:focus {
    }
  }

  .select-container.centered {
    select {
      text-align-last: center;
      text-align: -webkit-center;
      -ms-text-align-last: center;
      -moz-text-align-last: center;
    }
  }

  &.open {
    .chevron-container img {
      transform: scaleY(-1);
    }
  }

  &.invalid {
    select {
      border: 1px solid #C71F25;
    }
  }

  &.disabled {
    cursor: not-allowed;
    select {
      pointer-events: none;
      border-color: #c6cacc;
      color: #c6cacc;
    }

    .chevron-container {
      background-color: #c6cacc;
    }
  }

  .chevron-container {
    position: absolute;
    right: 4px;
    height: 24px;
    width: 24px;
    background-color: $kone-blue;
    top: 50%;
    border-radius: 2px;
    transform: translateY(-50%);
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
      position: absolute;
      right: -5.5px;
    }
  }

  &.size-big {
    select {
      line-height: 2.5rem;
      font-size: 18px;
    }

    .chevron-container {
      height: 34px;
      width: 34px;

      img {
        right: -1px;
      }
    }
  }
}

.rtl {
  .FormSelect {
    .chevron-container {
      right: unset;
      left: 4px;
    }
  }
}