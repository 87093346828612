@import '../../styles/Variables.scss';

$side-padding: .65rem;
$signout-component-diameter: 2.25rem; 

.LoginComponent {
  &.isOpen {
    background-color: rgba($kone-blue, .5);
  }

  height: 100%;
  padding: 0 $side-padding;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-button {
    line-height: 0;
    
    &:hover {
      cursor: pointer;
    }
  }
}

.SignOutComponent {
  position: relative;

  &__button.theme-circle {
    width: $signout-component-diameter;
    height: $signout-component-diameter;
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;  
         -moz-user-select: none; 
          -ms-user-select: none;  
              user-select: none;
  }

  &__profile-card {
    padding: 1rem 1.5rem;
    line-height: 1.5rem;
    min-width: 15rem;
    z-index: 5000;
    box-shadow: 1px 3px 5px #00000040;
    background-color: white;
    position: absolute;
    right: -($side-padding);
    display: flex;
    align-items: center;

    .profile-thumbnail {
      background-color: $kone-blue;
      color: white;
      min-width: 5rem;
      min-height: 5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      font-size: 3rem;
      border-radius: 100%;

      img {
        width: 100%;
      }
    }

    .profile-card-right {
      text-align: left;
      margin-left: 1rem;

      & > * {
        &:not(:last-child) {
          margin-top: .75rem;
          margin-bottom: .75rem;
        }
      }
    }

    .profile-card-header {
      font-size: 1.2rem;
    }

    .profile-card-email {
      font-size: .9rem;
    }

    .profile-card-sign-out {
      font-size: 1rem;
      display: flex;
      align-items: center;

      .svg-icon {
        margin-right: .5rem;
      }
    }
  }
}

.rtl {
  .SignOutComponent {
    &__profile-card {
      box-shadow: -1px 3px 5px rgba(0, 0, 0, .25);
      right: unset;
      left: -($side-padding);
  
      .profile-card-right {
        text-align: right;
        margin-right: 1rem;
        margin-left: 0;
      }
  
      .profile-card-sign-out {
        .svg-icon {
          margin-right: 0;
          margin-left: .5rem;
        }
      }
    }
  }
}

.Dialog.LoginDialog {
  @media only screen and (max-width: $tablet-portrait-small) {
    width: 95%;
  }

  .LoginDialog__header {
    display: flex;
    align-items: center;

    .svg-icon {
      margin-right: 1rem;
    }
  }

  .DialogBody.LoginDialog__body {
    height: 15rem;
    display: flex;
    justify-content: space-between;
    padding: 3rem 2rem;
    align-items: center;
    box-sizing: border-box;
    flex-direction: column;

    @media only screen and (max-width: $phone) {
      font-size: 14px;
    }

    .LoginDialog__button {
      width: 50%;

      @media only screen and (max-width: $phone) {
        width: 100%;
      }
    }
  }
}

.rtl {
  .Dialog.LoginDialog {
    .LoginDialog__header {
      .svg-icon {
        margin-right: 0;
        margin-left: 1rem;
      }
    }
  }
}