@import "../../styles/Variables";
@import "../../styles/utils.scss";

.Toast {
  box-shadow: 0px 3px 6px #00000033;
  border: 1px solid #C6CACC;
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: calc(#{$desktop-medium} - 2.4rem);
  // width: 100%;
  margin-right: 1.2rem;
  margin-left: 1.2rem;

  transition: all 0.3s ease-in-out;
  transform: translateY(-3.5rem);

  opacity: 0;

  padding: 0.8rem 0.8rem;
  align-items: center;

  &__icon-container {
    height: 1.35rem;
    flex: 0 0 1.35rem;
    margin-right: 0.8rem;

    .svg-icon {
      width: 100%;
      height: 100%;
    }
  }

  &__message {
    flex: 1 0;
    padding: 0;
    margin: 0;
  }

  &__close-button {
    height: 1rem;
    flex: 0 0 1rem;

    // Make clickable area larger
    margin: -0.5rem;
    padding: 0.5rem;

    cursor: pointer;

    .svg-icon {
      height: 100%;
      width: 100%;
    }
  }

  &--default {
    border-left: 8px solid #58AB27; 
    .Toast__icon-container .svg-icon {
      fill: #58AB27;
    }
  }
  &--warning {
    border-left: 8px solid #FFC500;
    .Toast__icon-container .svg-icon {
      fill: #FFC500;
    }
  }
  
  &--error {
    border-left: 8px solid $error-color;

    .Toast__icon-container .svg-icon {
      fill: $error-color;
    }
  }

  &--info {
    border-left: 8px solid $kone-blue;

    .Toast__icon-container .svg-icon {
      // fill: $error-color;
    }
  }

  &--visible {
    transform: translateY(0);
    opacity: 1;
  }

  &--clickable {
    cursor: pointer;
    
    &:hover {
      // transform: translateY(0.15rem);
      // box-shadow: 0px 2px 2px #00000033;
    }
  }
}