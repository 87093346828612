@import "../../../../styles/Variables";
@import "../../../../styles/utils";

.ProductContainer {
  .product-selection-box__title {
    .tag {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      min-width: 3.25rem;
      margin-left: 1rem;
      padding: 0 0.5rem;
      border-radius: 4px;
      background-color: #D4D7D9;
      color: #566066; 

      @include respond($phone-big) {
        margin-left: 0;
        margin-right: 50%; // Force second row
        margin-top: 0.5rem; // Match the bottom margin of the parent
      }

    }
  }

  .product-selection-box__product-data {
    position: relative;
    .new-tag {
      position: absolute;
      top: 10px;
      left: -70px;
      background-color: #58AB27;
      color: white;
      font-size: 14px;
      padding: 5px 14px 5px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      clip-path: polygon(88% 0, 100% 50%, 88% 100%, 0 100%, 0 0);

      @include respond($phone-big) {
        left: 24px;
        top: -10px;
        font-size: 12px;
        padding: 4px 14px 4px 10px;
      }
    }
  }
}

.ProductInfoBlock {

}