.LoadingSpinner {
  position: absolute;
  top: 0; 
  right: 0; 
  bottom: 0;
  left: 0;

  img {
    position: absolute;
    top: 0; 
    right: 0; 
    bottom: 0;
    left: 0;
    margin: auto;
    height: 128px; 
    width: 128px;
  }
}