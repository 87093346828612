@import "../../styles/Variables";

.product-selection-view-container.product-selection-view-container {
  @media only screen and (max-width: $phone-big){
    padding: 0;
  }
}

$padding-left: 6.75rem;
$padding-left-mobile: 1.5rem;

.ProductSelectionView {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: $phone-big){

  }

  .ProductFamilyContainerMarine {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    position: relative;

    @media only screen and (max-width: $tablet-portrait){ 
      margin-bottom: 0.5rem;
    }

    &__header {
      display: flex;
      @media only screen and (max-width: $tablet-portrait){ 
        margin-top: 3.5rem;
      }

      @media only screen and (max-width: $phone-big){
        flex-direction: column;
      }
    }
    
    &__info {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-left: 4rem;
      margin-top: 2rem;

      @media only screen and (max-width: $tablet-portrait){
        margin-left: 1.25rem;
        margin-top: 0;
        align-items: center;
        justify-content: center;
      }

      @media only screen and (max-width: $phone-big){
        margin: 1rem;
      }
    }

    &__image-container {
      img {
        width: 100%;
        height: auto;
      }

      flex-basis: 37.5%;

      @media only screen and (max-width: $tablet-portrait){
        flex-basis: 50%;
      }

      @media only screen and (max-width: $phone-big){
        flex-basis: 100%;
      }
    }

    &__products { 
      margin-top: 1rem;
    }

    &__title {
      font-size: 2.25rem;
      margin-bottom: 1.5rem;
      text-transform: uppercase;
      font-weight: 400;


      @media only screen and (max-width: $tablet-portrait){
        position: absolute;
        font-size: 1.5rem;
        left: 0;
        top: 0;
      }

      @media only screen and (max-width: $phone-big){
        margin-left: 1rem;
        font-size: 1.75rem;
      }
    }

    &__desc {
      font-size: 1.15rem;
      margin: 0;

      @media only screen and (max-width: $phone-big){
        font-size: 1rem;
      }   
    }
  }


  .ProductContainerMarine {
    border: 2px solid $gray;
    cursor: pointer;
    display: flex;
    padding: 1.1rem 0;
    flex-wrap: wrap;
    position: relative;
    z-index: 0;
    margin-top: -2px; // Offset borders

    @media only screen and (max-width: $phone-big){
      font-size: 0.85rem;
    }   

    &--selected {
      border: 2px solid $kone-blue;
      z-index: 1;

      .ProductContainerMarine__icon {
        fill: $kone-blue;
      }
    }

    &__icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 $padding-left;

      @media only screen and (max-width: $phone-big){
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
      }
    }

    &__icon {
      width: 2rem;
      height: 2rem;
      fill: #AAAFB3;
    }

    &__product-data {
      flex: 1 0;
    }

    &__title {
      margin: 0;
      margin-bottom: 0.5rem;
      font-size: 20px;
      font-weight: 400;
      padding: 10px 0 0;

      @media only screen and (max-width: $phone-big){
        padding-left: $padding-left-mobile;
      }      
    }

    &__desc {
      margin: 0;
      line-height: 1.4rem;
      min-height: 4.25rem; // Can fit three rows before growing in size
      margin-bottom: 0.5rem;

      @media only screen and (max-width: $tablet-portrait){
        min-height: 3rem; // Two rows
      }

      @media only screen and (max-width: $phone-big){
        padding-left: $padding-left-mobile;
        padding-right: 4rem;
      }   
    }

    &__select-container {
      flex: 1 0 100%;
      margin-left: $padding-left;
      margin-top: 0.5rem;

      @media only screen and (max-width: $tablet-portrait){
        margin-top: 1rem;;
      }

      @media only screen and (max-width: $phone-big){
        margin-left: $padding-left-mobile;

        flex: 1 1 100%;
        margin-right: 1rem;
      }
    }

    &__select {
      display: flex;

      .label-container {
        display: flex;

        .label {
          margin: 0;
        }
      }

      .select-container {
        margin-left: 1rem;
        width: 8rem;

        @media only screen and (max-width: $phone-big){
          width: unset;
          flex: 1;
        }   
      }
    }

    &__product-info {
      display: flex;
      align-items: center;
      margin-left: 2rem;
      flex: 0 0 52%;

      @media only screen and (max-width: $tablet-portrait){ 
        // Last value of the calc could be tought of as right padding
        flex: 0 0 calc(100% - #{$padding-left} - 2rem);
        margin: 0;
        padding-left: $padding-left;
      }

      @media only screen and (max-width: $phone-big){
        flex: 100%;
        padding-left: $padding-left-mobile;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .ProductInfoBlockMarine {
    display: flex;
    align-items: center;
    flex: 1;

    @media only screen and (max-width: $phone-big){
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }   
    
    &__icon {
      width: 2rem;
      height: 2rem;
    }

    &__values {
      line-height: 1.4rem;  
      margin-left: 0.75rem;

      @media only screen and (max-width: $phone-big){
        line-height: 1.2rem;
        margin-left: 1rem;
      }   
      
      p {
        margin: 0;
      }
    }

    &__label {
      color: #566066;
      text-transform: capitalize;

      @media only screen and (max-width: $phone-big){
        font-size: 0.9rem;
      }   
    }

    &__value {

    }
  }
}

.rtl {
  .ProductSelectionView {
    .ProductFamilyContainerMarine {
      &__header {
        display: flex;
      }
      
      &__info {
        margin-right: 4rem;
        margin-left: 0;
      }
    }
  
    .ProductContainerMarine {
      &__select-container {
        margin-left: 0;
        margin-right: $padding-left;
      
        
      }
  
      &__select {
        .select-container {
          margin-left: 0;
          margin-right: 1rem;       
        }
      }
  
      &__product-info {
        margin-left: 0;
        margin-right: 2rem;
      }
    }
  
    .ProductInfoBlockMarine {
      &__values {
        margin-left: 0;
        margin-right: 0.75rem;
      }
    }
  }
}