@import "../../styles/Variables";
@import "../../styles/utils.scss";

$condensed-width: 1024px;

.Welcome {
  // Utils
  .mb-1 { 
    margin-bottom: 10px; 

    @media only screen and (max-width: $phone-big) {
      margin-bottom: 0; 
    }
  }
  .mb-2 { margin-bottom: 20px; }

  .float-right {
    float: right;
  }

  section {
    padding: 60px 40px;

    &.main {
      padding-top: 40px;
    }

    header {
      max-width: $condensed-width;
      margin: auto;
      text-align: center;
    }

    @media only screen and (max-width: $phone-big){
      padding: 0;
    }
  }

  h2 {        
    font-size: 36px;
    font-weight: normal;
    margin: 0 0 60px;

    @media only screen and (max-width: $phone-big) {
      font-size: 24px;
      margin: 0 0 30px;
      padding: 0;
    }

    &.with-line {
      @include reader-line()
    }

    &.line-above-on-phone {
      @media only screen and (max-width: $phone-big) {
        font-size: 24px;
        margin-top: 45px;

        &::after {
          top: calc(100% - 65px);
        }
      }
    }

    &.no-line-on-phone {
      @media only screen and (max-width: $phone-big) {
        &::after {
          display: none;
        }
      }
    }
  }

  h3 {        
    font-size: 30px;
    font-weight: normal;
    margin: 0 0 20px;
    text-transform: uppercase;

    @media only screen and (max-width: $phone-big){
      font-size: 20px;
    }

    &.with-line {
      @include reader-line()
    }
  }

  .btn {
    @include btn-md();
    margin: 40px 0 0 0;
  }

  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////
  // welcome page for marine
  section.hero-marine {
    position: relative;

    .bg-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      
      .bg {
        width: 100%;
        height: 100%;

        background: linear-gradient(
          rgba(4, 42, 75, 0.31), 
          rgba(4, 42, 75, 0.31)
        ),
        url(../../assets/images/welcome-bg-marine.jpg);
        background-position: top;
        background-size: cover;
        animation: animatedBackground 3s;
      }      
    }

    color: white;
    padding: 100px 40px;

    @media only screen and (max-width: $phone-big){
      padding: 100px 0 50px 0;
      margin-bottom: 1.5rem;
    }
    
    header {
      position: relative;
      max-width: 540px;
      margin: auto;     

      p {
        margin: 30px 0 0 0;

      }
      .btn {
        margin: 40px 0 0 0;
        display: inline-block;
      }
    }

    .btn {
      min-width: 220px;
      width: 100%;
    }
  }

  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////
  // welcome page when modernization is not available
  section.hero-v1 {
    position: relative;

    .bg-container {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      
      .bg {
        width: 100%;
        height: 100%;

        background: linear-gradient(
          rgba(4, 42, 75, 0.31), 
          rgba(4, 42, 75, 0.31)
        ),
        url(../../assets/images/welcome-bg.jpg);
        background-position: top;
        background-size: cover;
        animation: animatedBackground 3s;
      }      
    }

    color: white;
    padding: 100px 40px;

    @media only screen and (max-width: $phone-big){
      padding: 100px 0 50px 0;
      margin-bottom: 1.5rem;
    }
    
    header {
      position: relative;
      max-width: 540px;
      margin: auto;     
      h1 {
        margin: 0; 
        font-size: 50px;
        font-weight: normal;

        @media only screen and (max-width: $tablet-portrait){
          font-size: 45px;
        }

        @media only screen and (max-width: $phone-big){
          font-size: 35px;
        }
      }
      p {
        margin: 30px 0 0 0;
      }
      .btn {
        margin: 40px 0 0 0;
        display: inline-block;
      }
    }

    .btn {
      min-width: 220px;
    }
  }

  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////
  // welcome page with modernization
  section.hero {
    position: relative;
    display: flex;
    padding: 0;
    box-sizing: border-box;

    @media only screen and (max-width: $phone-big){
      flex-direction: column;
      margin-bottom: 2rem;
    }
    
    .hero-section {
      box-sizing: inherit;
      position: relative;
      margin: 0;
      padding: 5rem 3.5rem;
      min-height: 42rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.35);

      .btn {
        text-shadow: none;
      }

      @media only screen and (max-width: $tablet-landscape) {
        min-height: 31.5rem;
        padding: 3.75rem 2.5rem 3rem;
      }

      @media only screen and (max-width: $tablet-portrait) {
        padding: 3.5rem 2.5rem;
      }

      @media only screen and (max-width: $tablet-portrait-small) {
        padding: 3rem 2.5rem;
      }
      
      @media only screen and (max-width: $phone-big) {
        min-height: 22.75rem;
        padding: 1.5rem 2.5rem;
      }

      p {
        margin: 40px 0 0 0;

        @media only screen and (max-width: $tablet-landscape) {
          margin-top: 28px;
        }
        
        @media only screen and (max-width: $tablet-portrait) {
          margin-top: 22px;
        }
        
        @media only screen and (max-width: $phone-big){
          margin-top: 18px;
        }
      }

      .btn {
        margin: 40px 0 0 0;
        width: 100%;
        height: 3rem;
        @include btn-lg();
        font-size: 24px;
        
        @media only screen and (max-width: $tablet-portrait){
          font-size: 20px;
          width: 100%;
        }
        
        @media only screen and (max-width: $phone-big){
          // width: 18rem;
          font-size: 18px;
        }
      }
      
  
      .lead-container {
        font-size: 24px;
        min-height: 20rem;

        .lead {
          padding: 0;
        }
        
        @media only screen and (max-width: $tablet-landscape) {
          font-size: 18px;
        }

        @media only screen and (max-width: $phone-big){
          min-height: 0rem;
          font-size: 16px;
        }
      }
  
      .btn {
        min-width: 220px;
        margin-top: 1.5rem;
      }
    }

    .new-buildings {
      flex: 1;
      background: 
        url(../../assets/images/welcome-bg-new-buildings.jpg);
        background-position: top;
        background-size: cover;
        
        &.ena {
          background: 
          url(../../assets/images/welcome-bg-new-buildings-ena.png);
          background-position: top;
          background-size: cover;  
          color: white;
          opacity: 1; // TODO: for some reason there is inline definition .ena { opacity:0 }, make sure that this is shown
        }
        .btn {
          @include btn-blue();
        }

        @media only screen and (max-width: $tablet-landscape) {
          background-position: 60%;
        }
        
        @media only screen and (max-width: $tablet-portrait) {
          background-position: 78%;
        }
        
        @media only screen and (max-width: $phone-big) {
          background-position: 0;
        }
    }

    .existing-buildings {
      flex: 1;
      background: 
        url(../../assets/images/welcome-bg-modernization.jpg);
      background-position: top;
      background-size: cover;
      color: white;

      &.ena {
        background: 
          url(../../assets/images/welcome-bg-modernization-ena.png);
        background-position: top;
        background-size: cover;
        color: white;
        opacity: 1; // TODO: for some reason there is inline definition .ena { opacity:0 }, make sure that this is shown
      }

      @media only screen and (max-width: $tablet-landscape) {
        background-position: 50%;
      }

      @media only screen and (max-width: $tablet-portrait) {
        background-position: 50%;
      }
      
      @media only screen and (max-width: $phone-big) {
        background-position: 0;
        // background-size: 120%;
      }

      @media only screen and (max-width: $phone-small) {
        &.existing-buildings {
          // background-position: 0% 0%;
          background-size: cover;
        }
      }

      .btn {
        @include btn-white();
      }
    }
  }

  section.related {
    padding: 0 40px 60px 40px;

    @media only screen and (max-width: $phone-big) {
      padding: 40px 0;
    }
  }

  section header {
    @media only screen and (max-width: $phone-big) {
      padding: 0 2.5rem;
    }
  }

  section {
    .lead {
      margin-bottom: 60px;
    }
  }

  section.footer {
    padding: 0 40px 60px 40px;
    text-align: center;

    .legal-notes {
      font-size: 14px;
      color: $dark-gray;
      max-width: $condensed-width;
      margin: auto;
    }

    .app-version {
      margin: 20px auto 0 auto;
      font-size: 14px;
      color: $dark-gray;
      max-width: $condensed-width;
      
    }

    @include respond($phone-big) {
      margin-top: 1.25rem;
    }
  }

  .card {
    position: relative;
    display: flex;
    background-color: #fff;    
    box-sizing: border-box;

    @media only screen and (max-width: $phone-big){
      flex-direction: column-reverse;

      &.reversed {
        flex-direction: column;
      }
    }

    &.with-border {
      border: solid 1px #dae2e7;
    }

    &.card-md {
      max-width: $condensed-width;
      margin-right: auto;
      margin-left: auto;


      .body {
        padding: 40px;
      }

    }

    .media {
      box-sizing: border-box;
      width: 50%;
      img {
        display: block;        
        margin: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        font-family: "object-fit: cover; object-position: center;";
      }

      @media only screen and (max-width: $phone-big){
        width: 100%;
      }
    }

    .body {
      box-sizing: border-box;
      width: 50%;
      padding: 60px;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media only screen and (max-width: $phone-big){
        width: 100%;
      }

      .btn {
        @media only screen and (max-width: $tablet-portrait){
          display: block;
          width: 100%;
        }
      }

      .store-links-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top: 2rem;

        @media only screen and (max-width: $tablet-portrait){

        }

        @media only screen and (max-width: $phone-big){
          flex-direction: row;
        }

        .img-container {
          height: 9rem;
          width: 9rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          @media only screen and (max-width: $tablet-portrait){
            height: 10rem;
            justify-content: flex-start;
          }
  
          .qr-code {
            width: 8rem; 
            height: auto; 
            @media only screen and (max-width: $tablet-portrait){
              width: 7rem; 
            }
          }

          .store-badge {
            width: 7em; 
            height: auto; 
            @media only screen and (max-width: $tablet-portrait){
              width: 6rem; 
            }
          }
        }
      }

      .btn-bar {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 20px 0 0 0;
        .btn {
          margin: 20px 0 0 0;
          &:first-child {
            margin: 20px 10px 0 0;
          }
          @media only screen and (max-width: $tablet-portrait) {
            &:first-child {
              margin: 20px 0 0 0;
            }
          }
        }
      }
    }
  }

  .body-text {
    font-size: clamp(14px, 2vw, 17px);
    margin: 0;
  }
  
  .btn-kone-blue {
    @include btn-lg();
    @include btn-blue();
  }

  .btn-transparent {
    @include btn-lg();
    @include btn-white();
  }

  .btn-full-width {
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
  }

  .btn-icon {
    margin-right: 1rem;
    height: clamp(1rem, 2vw, 1.5rem);
  }

  .hero-content {
    @media only screen and (max-width: $phone-big){
      padding: 0 40px;
    }
  }

  .hero-section-title {
    font-size: 40px;
    font-size: clamp(28px, 5vw, 40px);
    font-weight: 400;
    margin-bottom: clamp(15px, 4vh, 40px);
  }

  .action-button {
    margin-top: clamp(14px, 6vh, 60px);
    font-size: clamp(16px, 2.5vw, 24px);

    & + .action-button {
      margin-top: 1rem;
    }
  }

  .section-heading {
    font-size: 30px;
    font-size: clamp(18px, 3vw, 32px);
  }

  .lead {
    font-size: 24px;
    font-size: clamp(16px, 3vw, 24px);
    line-height: clamp(18px, 3.1vw, 30px);
    margin: 0;

    & + .lead {
      margin-top: clamp(35px, 6vh, 50px);
    }
    
    @media only screen and (max-width: $phone-big){
      text-align: left;
    }
  }

  .MobileAppSection:not(.wide-version) {
    padding-top: 0;
    margin-top: -60px;
  }

  .MobileAppSection.wide-version {
    @include respond($phone-big) {
      margin-top: 1rem;
    }
  }
}

.sticky-header {
  .Welcome {
    margin-top: $header-height;
    
    @media only screen and (max-width: $phone-big) {
      margin-top: 125px;
    }
  }
}

@keyframes animatedBackground {
  from { 
    transform: translateX(-5%) scale(1.1);
    // scale(1.1);
  }
  to { 
    transform: translateX(0) scale(1); 
    // scale(1);
  }
}