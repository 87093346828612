.svg-247connected-dims {
	width: 96px;
	height: 96px;
}

.svg-Doors-sel-both-dims {
	width: 37px;
	height: 53px;
}

.svg-Doors-sel-left-dims {
	width: 37px;
	height: 53px;
}

.svg-Doors-sel-right-dims {
	width: 36px;
	height: 53px;
}

.svg-Spinner-dims {
	width: 24px;
	height: 24px;
}

.svg-deep-dims {
	width: 36.67px;
	height: 70px;
}

.svg-deep_au_14_20_24-dims {
	width: 46.67px;
	height: 66.67px;
}

.svg-deep_au_14_20_24_selected-dims {
	width: 46.67px;
	height: 66.67px;
}

.svg-deep_selected-dims {
	width: 36.67px;
	height: 70px;
}

.svg-edit-dims {
	width: 19.85px;
	height: 19.837px;
}

.svg-ena_17_25_24_4500hmc-dims {
	width: 56.67px;
	height: 83.34px;
}

.svg-ena_17_25_24_4500hmc_selected-dims {
	width: 56.67px;
	height: 83.34px;
}

.svg-homelift_9_12_24-dims {
	width: 30px;
	height: 40px;
}

.svg-homelift_9_12_24_selected-dims {
	width: 30px;
	height: 40px;
}

.svg-icon-1-panel-dims {
	width: 46px;
	height: 53px;
}

.svg-icon-2-panels-dims {
	width: 46px;
	height: 53px;
}

.svg-icon-3-panels-dims {
	width: 46px;
	height: 53px;
}

.svg-icon-accessories-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-accessories-del-dims {
	width: 28px;
	height: 28px;
}

.svg-icon-accessories-edit-dims {
	width: 34px;
	height: 34px;
}

.svg-icon-accessories-selected-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-alert-dims {
	width: 23px;
	height: 21px;
}

.svg-icon-arrow-blue-dims {
	width: 15px;
	height: 13.537px;
}

.svg-icon-arrow-right-dims {
	width: 20px;
	height: 20px;
}

.svg-icon-brightness-dims {
	width: 20px;
	height: 20px;
}

.svg-icon-cancel-dims {
	width: 11.703px;
	height: 11.725px;
}

.svg-icon-ceiling-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-ceiling-selected-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-center-panel-dims {
	width: 46px;
	height: 53px;
}

.svg-icon-check-dims {
	width: 15px;
	height: 15px;
}

.svg-icon-check-green-dims {
	width: 15px;
	height: 15px;
}

.svg-icon-check-white-dims {
	width: 15px;
	height: 15px;
}

.svg-icon-checkmark-circle-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-chevron-down-dims {
	width: 15px;
	height: 15px;
}

.svg-icon-chevron-down-lg-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-chevron-left-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-chevron-up-dims {
	width: 15px;
	height: 15px;
}

.svg-icon-chevron-up-lg-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-close-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-close-colorless-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-close-white-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-combined-left-panel-dims {
	width: 46px;
	height: 53px;
}

.svg-icon-combined-right-panel-dims {
	width: 46px;
	height: 53px;
}

.svg-icon-contact-dims {
	width: 29.229px;
	height: 22.457px;
}

.svg-icon-contrast-dims {
	width: 20px;
	height: 20px;
}

.svg-icon-copy-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-deco-mirrors-centered-full-height-glazing-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-deco-mirrors-centered-full-height-mirrors-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-deco-mirrors-full-glazed-back-wall-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-deco-mirrors-full-mirror-back-wall-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-deco-mirrors-mirrored-back-corners-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-deco-mirrors-mirrored-back-corners-and-glazed-back-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-deco-mirrors-none-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-deco-mirrors-offset-mirror-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-delete-dims {
	width: 25px;
	height: 25px;
}

.svg-icon-digital-services-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-digital-services-selected-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-done-dims {
	width: 14.563px;
	height: 11.738px;
}

.svg-icon-doors-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-doors-selected-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-download-dims {
	width: 20px;
	height: 21.359px;
}

.svg-icon-edit-dims {
	width: 20px;
	height: 20px;
}

.svg-icon-edit-field-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-edit-pen-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-edit-pen-blue-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-elevator-dims {
	width: 21px;
	height: 33px;
}

.svg-icon-expand-dims {
	width: 25px;
	height: 25px;
}

.svg-icon-floor-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-floor-selected-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-gallery-dims {
	width: 24px;
	height: 24px;
}

.svg-icon-hall-indicator-horizontal-dims {
	width: 36px;
	height: 16px;
}

.svg-icon-hall-indicator-vertical-dims {
	width: 16px;
	height: 33px;
}

.svg-icon-handset-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-horizontal-panels-dims {
	width: 74px;
	height: 84px;
}

.svg-icon-horizontal-panels-active-dims {
	width: 74px;
	height: 84px;
}

.svg-icon-import-dims {
	width: 20px;
	height: 20px;
}

.svg-icon-info-dims {
	width: 18px;
	height: 18px;
}

.svg-icon-info-medium-dims {
	width: 25px;
	height: 25px;
}

.svg-icon-info-square-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-invalid-password-dims {
	width: 25px;
	height: 22px;
}

.svg-icon-landing-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-landing-call-dims {
	width: 16px;
	height: 30px;
}

.svg-icon-landing-editor-dims {
	width: 280px;
	height: 300px;
}

.svg-icon-left-and-right-panel-dims {
	width: 46px;
	height: 53px;
}

.svg-icon-left-panel-dims {
	width: 46px;
	height: 53px;
}

.svg-icon-light-blue-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-light-copper-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-light-white-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-light-yellow-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-like-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-list-dims {
	width: 16px;
	height: 18px;
}

.svg-icon-lock-dims {
	width: 8px;
	height: 12px;
}

.svg-icon-lock-active-dims {
	width: 8px;
	height: 12px;
}

.svg-icon-login-dims {
	width: 26px;
	height: 26px;
}

.svg-icon-logout-dims {
	width: 26px;
	height: 26px;
}

.svg-icon-map-location-dims {
	width: 25px;
	height: 31px;
}

.svg-icon-max-load-dims {
	width: 38px;
	height: 24px;
}

.svg-icon-max-speed-dims {
	width: 32px;
	height: 30px;
}

.svg-icon-max-travel-dims {
	width: 27px;
	height: 32px;
}

.svg-icon-menu-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-message-dims {
	width: 24px;
	height: 18px;
}

.svg-icon-mix-dims {
	width: 20px;
	height: 20px;
}

.svg-icon-model-and-layout-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-moon-dims {
	width: 25px;
	height: 25px;
}

.svg-icon-no-image-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-offset-rotate-dims {
	width: 20px;
	height: 20px;
}

.svg-icon-offset-x-dims {
	width: 20px;
	height: 20px;
}

.svg-icon-offset-y-dims {
	width: 20px;
	height: 20px;
}

.svg-icon-open-left-dims {
	width: 28.313px;
	height: 35px;
}

.svg-icon-open-right-dims {
	width: 28.313px;
	height: 35px;
}

.svg-icon-password-dims {
	width: 14px;
	height: 20px;
}

.svg-icon-paste-dims {
	width: 15px;
	height: 15px;
}

.svg-icon-pause-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-pdf-dims {
	width: 52px;
	height: 60px;
}

.svg-icon-play-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-plus-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-premium-dims {
	width: 16px;
	height: 16px;
}

.svg-icon-premium-ena-dims {
	width: 16px;
	height: 16px;
}

.svg-icon-premium-ena-inverse-dims {
	width: 16px;
	height: 16px;
}

.svg-icon-premium-inverse-dims {
	width: 16px;
	height: 16px;
}

.svg-icon-question-dims {
	width: 37.66px;
	height: 37.66px;
}

.svg-icon-remove-item-dims {
	width: 12px;
	height: 12px;
}

.svg-icon-right-panel-dims {
	width: 46px;
	height: 53px;
}

.svg-icon-rotate-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-save-dims {
	width: 24px;
	height: 24px;
}

.svg-icon-select-dims {
	width: 25px;
	height: 15px;
}

.svg-icon-select-active-dims {
	width: 25px;
	height: 15px;
}

.svg-icon-select-white-dims {
	width: 25px;
	height: 15px;
}

.svg-icon-settings-dims {
	width: 30px;
	height: 30px;
}

.svg-icon-share-url-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-signalization-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-signalization-selected-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-smiley-frustrated-dims {
	width: 32px;
	height: 32px;
}

.svg-icon-smiley-happy-dims {
	width: 32px;
	height: 32px;
}

.svg-icon-smiley-like-dims {
	width: 32px;
	height: 32px;
}

.svg-icon-smiley-neutral-dims {
	width: 32px;
	height: 32px;
}

.svg-icon-smiley-sad-dims {
	width: 32px;
	height: 32px;
}

.svg-icon-success-dims {
	width: 70px;
	height: 69.996px;
}

.svg-icon-sun-dims {
	width: 25px;
	height: 25px;
}

.svg-icon-tool-dims {
	width: 40px;
	height: 39.973px;
}

.svg-icon-tool-black-dims {
	width: 96px;
	height: 96px;
}

.svg-icon-twitter-dims {
	width: 22px;
	height: 18px;
}

.svg-icon-undo-dims {
	width: 15px;
	height: 15px;
}

.svg-icon-upload-dims {
	width: 42px;
	height: 44px;
}

.svg-icon-username-dims {
	width: 20px;
	height: 18px;
}

.svg-icon-vertical-panels-dims {
	width: 74px;
	height: 84px;
}

.svg-icon-vertical-panels-active-dims {
	width: 74px;
	height: 84px;
}

.svg-icon-wall-a-dims {
	width: 40px;
	height: 55px;
}

.svg-icon-wall-b-dims {
	width: 40px;
	height: 55px;
}

.svg-icon-wall-c-dims {
	width: 40px;
	height: 55px;
}

.svg-icon-wall-d-dims {
	width: 40px;
	height: 55px;
}

.svg-icon-walls-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-walls-selected-dims {
	width: 40px;
	height: 40px;
}

.svg-icon-weather-dims {
	width: 24px;
	height: 24px;
}

.svg-icon-wheelchair-dims {
	width: 23px;
	height: 35px;
}

.svg-india_11_10_22-dims {
	width: 36.67px;
	height: 33.33px;
}

.svg-india_11_10_22_selected-dims {
	width: 36.67px;
	height: 33.33px;
}

.svg-india_11_13_22-dims {
	width: 33.25px;
	height: 43.34px;
}

.svg-india_11_13_22_selected-dims {
	width: 33.25px;
	height: 43.34px;
}

.svg-india_11_20_22-dims {
	width: 36.67px;
	height: 70px;
}

.svg-india_11_20_22_selected-dims {
	width: 36.67px;
	height: 70px;
}

.svg-india_13_24_22-dims {
	width: 36.67px;
	height: 70px;
}

.svg-india_13_24_22_selected-dims {
	width: 36.67px;
	height: 70px;
}

.svg-india_16_13_22-dims {
	width: 53.33px;
	height: 46.67px;
}

.svg-india_16_13_22_selected-dims {
	width: 53.33px;
	height: 46.67px;
}

.svg-india_16_15_22-dims {
	width: 53.33px;
	height: 46.67px;
}

.svg-india_16_15_22_selected-dims {
	width: 53.33px;
	height: 46.67px;
}

.svg-kone-logo-dims {
	width: 76px;
	height: 38px;
}

.svg-main-ceiling-dims {
	width: 40px;
	height: 40px;
}

.svg-main-ceiling-selected-dims {
	width: 40px;
	height: 40px;
}

.svg-mobile-comm-dims {
	width: 96px;
	height: 96px;
}

.svg-music-dims {
	width: 96px;
	height: 96px;
}

.svg-nanospace_11_10_21-dims {
	width: 36.67px;
	height: 33.33px;
}

.svg-nanospace_11_10_21_selected-dims {
	width: 36.67px;
	height: 33.33px;
}

.svg-office-dims {
	width: 96px;
	height: 96px;
}

.svg-robot-dims {
	width: 96px;
	height: 96px;
}

.svg-single-dims {
	width: 54px;
	height: 54.845px;
}

.svg-single_selected-dims {
	width: 54px;
	height: 54.845px;
}

.svg-square-dims {
	width: 36.67px;
	height: 46.67px;
}

.svg-square_10_13_24-dims {
	width: 33.25px;
	height: 43.34px;
}

.svg-square_10_13_24_selected-dims {
	width: 33.25px;
	height: 43.34px;
}

.svg-square_selected-dims {
	width: 36.67px;
	height: 46.67px;
}

.svg-statistics-dims {
	width: 96px;
	height: 96px;
}

.svg-status-success-dims {
	width: 24px;
	height: 24px;
}

.svg-transys_12_23_24-dims {
	width: 39.992px;
	height: 76.669px;
}

.svg-transys_12_23_24_selected-dims {
	width: 39.992px;
	height: 76.669px;
}

.svg-transys_12_26_24-dims {
	width: 39.992px;
	height: 86.67px;
}

.svg-transys_12_26_24_selected-dims {
	width: 39.992px;
	height: 86.67px;
}

.svg-transys_14_24_24-dims {
	width: 46.657px;
	height: 80.003px;
}

.svg-transys_14_24_24_selected-dims {
	width: 46.657px;
	height: 80.003px;
}

.svg-transys_15_27_24-dims {
	width: 49.99px;
	height: 90.003px;
}

.svg-transys_15_27_24_selected-dims {
	width: 49.99px;
	height: 90.003px;
}

.svg-ttc-dims {
	width: 54px;
	height: 54.845px;
}

.svg-ttc_ena-dims {
	width: 54px;
	height: 54.845px;
}

.svg-ttc_ena_selected-dims {
	width: 54px;
	height: 54.845px;
}

.svg-ttc_selected-dims {
	width: 54px;
	height: 54.845px;
}

.svg-wide-dims {
	width: 53.33px;
	height: 46.67px;
}

.svg-wide_23_17_26-dims {
	width: 76.67px;
	height: 56.66px;
}

.svg-wide_23_17_26_selected-dims {
	width: 76.67px;
	height: 56.66px;
}

.svg-wide_au_14_16_24-dims {
	width: 46.67px;
	height: 53.33px;
}

.svg-wide_au_14_16_24_selected-dims {
	width: 46.67px;
	height: 53.33px;
}

.svg-wide_ena_19_16_24-dims {
	width: 63.33px;
	height: 53.33px;
}

.svg-wide_ena_19_16_24_selected-dims {
	width: 63.33px;
	height: 53.33px;
}

.svg-wide_ena_20_13_24-dims {
	width: 73.33px;
	height: 43.33px;
}

.svg-wide_ena_20_13_24_selected-dims {
	width: 73.33px;
	height: 43.33px;
}

.svg-wide_selected-dims {
	width: 53.33px;
	height: 46.67px;
}

