@import "../../styles/Variables";

.App {    
  background-color: $layout-bg;
  overflow: hidden;

  &.displaying-translation-keys {
    * {
      text-transform: none !important;
    }
  }

  .android-prompt {
    position: fixed;
    z-index: 10000;
    background-color: $layout-bg;
    // border-top: 2px solid $kone-blue;
    box-sizing: border-box;
    height: 5rem;
    padding: 0.5rem 1rem;
    padding-bottom: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
    justify-content: center;
    align-items: center;
    max-width: 30rem;
    margin: 0 auto;

    .install {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: -10px;
      max-width: 27rem;

      .download {
        position: absolute;
        left: 0;
        top: -1rem;
        margin: 0;
        font-size: 13px;
        color: $dark-gray;
        text-transform: uppercase;
      }

      p {
        font-size: 21px;
        margin: 0;
        padding: 0;
      }

      img {
        height: 1.75rem;
        margin-top: 5px;
        width: auto;
        margin-right: 20px;
      }
    }

    .close-icon {
      padding: 7px;
      position: absolute;
      height: 1rem;
      width: 1rem;
      top: 0;
      right: 0;

      .svg-icon {
        width: 100%;
        height: 100%;
      }
    }

    .Button {
      font-size: 14px;
      padding: 9px 15px;
    }

    img {
      margin-right: 1rem;
      width: 2rem;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
  z-index: 2;
  margin-bottom: 3rem;
}

.rtl {
  direction: rtl;
  unicode-bidi: isolate;

  .android-prompt {
    .install {
      .download {
        left: unset;
        right: 0;
      }
    }

    .close-icon {
      right: unset;
      left: 0;
    }
  }
}