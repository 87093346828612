@import "../../../../styles/Variables";
@import "../../../../styles/utils.scss";


.InfoDialog {
 
  
  .InfoItem {
    display: flex;
    margin-bottom: 1rem;

    .image-container {
      margin-right: 1.5rem;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      flex: 0 0 7rem;
      height: 7rem;

      .Sprite {
        width: 100%;
        height: 100%;
        padding: 0 !important;
      }
    }

    .content-container {
      flex: 1 0;

      h4 {
        color: $kone-blue;
        font-weight: 400;
        font-size: 1.4rem;
        margin: 0;
        margin-top: 1.5rem;
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0;
      }
    }
  }

  .footer-buttons {
    text-align: right;
    .Button {
      min-width: 128px;
      margin-right: 10px;
      &:last-child {
        margin-right: unset;
      }
    }

    @media only screen and (max-width: $phone-big){
      display: flex;

      .Button {
        flex: 1;
      }
    }

    @media only screen and (max-width: $phone-small){
      padding: 10px 15px;
    }
  }

  // role selection area
  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding-bottom: 1rem;

    height: 17rem;
    margin-left: -30px;
    margin-right: -30px;
    margin-top: -15px;
  }

  .section-image-container {
    width: 7rem;
    min-width: 7rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;

    .Sprite, .map-icon {
      width: auto;
      height: 3.1rem;
      fill: white;
    }

    .role-image {
      padding-top: 0 !important;
      height: 5rem;
      width: 5rem;
    }
  }

  .section-title {
    margin-top: 0.75rem;
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: 400;
  }

  .FormSelect {
    width: 17rem;
    margin-top: 1rem;

    @include respond($phone-big) {
      width: 100%;
    }
  }

  .role-section {
    padding-top: 2rem;
  }

  .section-content {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @include respond($phone-big) {
      width: 85%;
      line-height: 1.5rem;
    }
  }

}