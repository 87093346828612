@import "../../styles/Variables";
@import "../../styles/utils";

.DesignInfo {
  .block:last-child {
    border: none;
  }

  .TileImage {
    min-height: 75px;
    min-width: 75px;
    height: 75px;
    width: 75px;
  }

  .finish-label {
    font-size: 12px;
  }

  .block {
    padding: 2rem 0;
    border-bottom: 1px solid $border-color;

    @include respond($phone-big) {
      padding: 1.25rem 0;
    }

    .head {
      font-size: 1.25rem;
      margin-top: 0;
    }

    .sub-head {
      font-size: 1.15rem;
      margin-top: 0;
    }

    .sub-head-landing {
      margin-top: 2.5rem;
    }

    .item {
      display: flex;
      margin: 1rem 0;

      .type {
        color: $dark-gray;
        width: 40%;
        text-transform: uppercase;
        .inlineIcon {
          display: inline-block;
          padding-left: 20px;
          vertical-align: top;
          .tooltipHeader {
            text-transform: none;
            padding-bottom: 5px;
          }
          .premium-icon {
            opacity: 0.5;
            display: flex;
            align-items: center;          
            height: 30px;
            .premium-text {
              text-transform: none;
              padding-left: 8px;
              font-size: 0.75rem;
            }
          }
        }

        .serviceImage {
          width: 85%;

          @media screen and (max-width: $tablet-portrait) {
            width: 90%;
          }

          @media screen and (max-width: $phone-big) {
            width: 100%;
          }

        }
      }

      @media screen and (max-width: $phone-big) {
        flex-direction: column;

        .type {
          margin-bottom: 0.25rem;
          width: 100%;    
        }
      }

      .floorFinish {
        display: flex;
        flex-direction: row;

        .finish-label {
          font-size: 14px;
          padding-left: 16px;
          margin: 0;
        }
      }

      .service {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-basis: 80%;

        .serviceTitle {
          font-size: 1.2rem;
          margin-bottom: 15px;          
          @media screen and (max-width: $phone-big) {
            margin-top: 15px;
          }
        }
        .serviceBody {
          font-size: 1rem;
          margin-bottom: 15px;          
        }
        .serviceLink {

          @media screen and (max-width: $phone-big) {
            margin-bottom: 20px;
          }

        }
      }
      
    }

    .wallFinishes {
      box-sizing: border-box;
      flex-wrap: wrap;
      width: 60%;

      .wallFinish {
        box-sizing: border-box;
        min-width: 141px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: row;

        .icon-premium {
          position: absolute;
          right: 6px;
          bottom: 3px;
        }

        .wallFinishTexts {
          display: flex;
          flex-direction: column;
          padding-left: 16px;

          .topLine {
            margin-top: 8px;
            width: 100%;
            height: 6px;
            background-color: $gray;
            opacity: 0.5;
          }
          .position {
            opacity: 0.5;
            display: block;
            font-size: 14px;
            margin: 0;
            padding-bottom: 5px;
          }
          .finish-label {
            font-size: 14px;
            margin: 0;
          }
        }
    
      }
      
      @media screen and (max-width: $phone-big) { // .wallFinishes
        width: 100%;

      }

      .InfoBox {
        width: 100%;
        padding: 12px 15px;
        border-radius: 4px;

        .icon-container {
          .svg-icon {
            height: 20px;
          }
        }
      }
    }
  }
}

.rtl {
  .DesignInfo {
    .block {
      .wallFinishes {
        .wallFinish {
          padding-right: unset;
          padding-left: 1rem;
        }
      }
    }
  }
}